import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

var config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID
}
firebase.initializeApp(config)
firebase.auth().useDeviceLanguage()
const firebaseFunctions = firebase.app().functions('asia-northeast1')

if (process.env.VUE_APP_CONNECT_EMULATOR === 'yes' || process.env.VUE_APP_EMULATOR_FUNCTIONS_ONLY === 'yes') {
  firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
}

export { firebase, firebaseFunctions }
