import { stripeCouponHandler } from '@/helpers/firebase-functions'

export const store = () => ({})

export const mutations = {
  resetState (state) {
  }
}

export const actions = {
  getCouponName (context, couponId) {
    return stripeCouponHandler(couponId)
  }
}
